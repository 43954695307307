import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Hero = ({ data }) => {
  const { section1Title, section1Content } = data
  return (
    <div className="common_banner_section">
      <div className="container">
        <div className="banner_center_section">
          <h1>{section1Title}</h1>
          <div className="banner_info">
            <p>{renderRichText(section1Content)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
