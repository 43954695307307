import React, { useState } from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Modal from "react-modal"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const School = ({ data }) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    orgName: "",
    trainingType: "",
    amountofPeople: "",
    trainingDate: new Date(),
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const { name, email, orgName, trainingType, amountofPeople, trainingDate } =
    values

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChange = selectedOption => {
    setValues({ ...values, trainingType: selectedOption })
    // console.log(`Option selected:`, selectedOption);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    
    setError("")
    setSuccess(false)

    if (
      !name ||
      !email ||
      !orgName ||
      !trainingType.value ||
      !amountofPeople ||
      !trainingDate
    ) {
      setError("All fields are required")
      return
    }

    if (!validateEmail(email)) {
      setError("Please provide a valid email")
      return
    }
    try {
      const res = await fetch("/.netlify/functions/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok) {
        setSuccess(true);
        document.querySelector("form").classList.add("formSubmitted");
        document.querySelector("form").disabled = true;
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }

  // const [startDate, setStartDate] = useState(new Date())

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      border: "1px solid #fff",
      color: "red",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#bf1116" : "#bf1116",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#bf1116" : "#bf1116",
      },
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      border: "1px solid #fff",
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      background: "#151515",
      color: "#bf1116",
    }),
  }

  const options = [
    { value: "CDR", label: "CDR" },
    { value: "COMMBNavigator®", label: "COMMBNavigator®" },
    { value: "Measurement 101", label: "Measurement 101" },
    { value: "OOH 101", label: "OOH 101" },
  ]

  // const [] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }

  Modal.setAppElement("#___gatsby")

  const { section2Title, section2ShortText, section2Content1 } = data
  return (
    <div className="school_section">
      <div className="container">
        <div className="school_block">
          <div className="commn_title_label">
            <p>{section2ShortText}</p>
          </div>
          <div className="common_inner_title">
            <h3>{section2Title}</h3>
          </div>
          <div className="school_content">
            <p>{renderRichText(section2Content1)}</p>
            <a className="common_btn" onClick={openModal}>
              Request Training
            </a>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        bodyOpenClassName={`BookCOMMBModal modalOpen trainingModal`}
      >
        <div className="common_section_title">
          <h2>
            Require <span> Training?</span>
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Full Name"
            required
            className="textInput"
            name="name"
            value={name}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Email Address"
            required
            className="textInput"
            name="email"
            value={email}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Organization Name"
            required
            className="textInput"
            name="orgName"
            value={orgName}
            onChange={handleChange}
          />
          <Select
            options={options}
            className={`customSelect custom-option`}
            styles={customStyles}
            required
            placeholder={`Select Training Type`}
            value={trainingType}
            onChange={onChange}
          />
          <input
            type="text"
            className="hidden"
            name="trainingType"
            value={trainingType.value}
          />
          <input
            type="text"
            placeholder="Amount of People"
            required
            className="textInput"
            name="amountofPeople"
            value={amountofPeople}
            onChange={handleChange}
          />
          <p>When would you like training to occur?</p>
          <DatePicker
            selected={trainingDate}
            placeholder={`When would you like training to occur?`}
            name="trainingDate"
            // value={trainingDate}
            onChange={date => setValues({ ...values, trainingDate: date })}
          />
           <button className="submit"><span className="submitText">Submit</span>
                {success && ( <span className="thankYouMsg">
                    Thank you for contacting us! We'll be in touch
                    with you soon.</span> )}
            </button>
        </form>
        <a className="closeBtnModal" onClick={closeModal}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.688"
            height="17.688"
            viewBox="0 0 17.688 17.688"
          >
            <path
              id="close-icon"
              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
              transform="translate(-7.5 -7.5)"
              fill="#fff"
            ></path>
          </svg>
        </a>
      </Modal>
    </div>
  )
}

export default School
