import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/education/hero"
import School from "../components/education/school"

const Education = ({ data }) => (
  <div className="educationPage">
    <Layout>
      <Seo title="Education" />
      <div className="main_content_wrapper education_page">
        <Hero data={data.contentfulEducation} />
        <School data={data.contentfulEducation} />
      </div>
    </Layout>
  </div>
)

export default Education

export const query = graphql`
  query Education($language: String) {
    contentfulEducation(node_locale: { eq: $language }) {
      section1Title
      section1Content {
        raw
      }
      section2Title
      section2ShortText
      section2Content1 {
        raw
      }
    }
  }
`
